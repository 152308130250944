<template>
  <div v-loading="btnloading" class="page-card-demo  page-info-content group-quota-detail">
    <div class="title">
      基本信息
    </div>
    <ul class="info-list clear">
      <li class="flex-box">
        <span class="label">集团名称：</span><span class="info flex1">{{ detailList.groupName }}</span>
      </li>
      <li class="flex-box">
        <span class="label">资金方名称：</span><span class="info flex1">{{ detailList.bankName }}</span>
      </li>
      <li class="flex-box">
        <span class="label">总额度(元)：</span><span class="info flex1">{{ detailList.quotaSum|formatMoney }}</span>
      </li>
      <li class="flex-box">
        <span class="label">可用额度(元)：</span><span class="info flex1">{{ detailList.quotaAbled|formatMoney }}</span>
      </li>
      <li class="flex-box">
        <span class="label">冻结额度(元)：</span><span class="info flex1">{{ detailList.quotaDisabled|formatMoney }}</span>
      </li>
      <li class="flex-box">
        <span class="label">已用额度(元)：</span><span class="info flex1">{{ detailList.quotaUsed|formatMoney }}</span>
      </li>
      <li class="flex-box">
        <span class="label">额度到期日：</span><span class="info flex1">{{ detailList.quotaDue|date }}</span>
      </li>
    </ul>
    <div class="title">
      子核心企业列表
    </div>
    <div class="table-box">
      <el-table :border="true" :data="detailList.quotaCoreList" class="br-table">
        <el-table-column label="核心企业名称" prop="coreName" min-width="120" />
        <el-table-column label="总额度(元)" prop="quotaSum" min-width="120">
          <template slot-scope="scope">
            {{ scope.row.quotaSum|formatMoney }}
          </template>
        </el-table-column>
        <el-table-column label="可用额度(元)" prop="quotaAbled" min-width="120">
          <template slot-scope="scope">
            {{ scope.row.quotaAbled|formatMoney }}
          </template>
        </el-table-column>
        <el-table-column label="冻结额度(元)" prop="quotaDisabled" min-width="120">
          <template slot-scope="scope">
            {{ scope.row.quotaDisabled|formatMoney }}
          </template>
        </el-table-column>
        <el-table-column label="已用额度(元)" prop="quotaUsed" min-width="130">
          <template slot-scope="scope">
            {{ scope.row.quotaUsed|formatMoney }}
          </template>
        </el-table-column>
        <el-table-column label="额度到期日" prop="quotaDue" min-width="120">
          <template slot-scope="scope">
            {{ scope.row.quotaDue|date }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>

import { selectGroupByPrimaryKey } from '@/api/tradeFinancing'
export default {
  data() {
    return {
      detailList: {},
      btnloading: false
    }
  },
  created() {
    this.selectDetail(this.$route.query.id)
  },
  methods: {
    selectDetail(id) {
      this.btnloading = true
      selectGroupByPrimaryKey(id, res => {
        if (res.code === 200) {
          this.detailList = res.data
        }
        this.btnloading = false
      }, () => {
        this.btnloading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .group-quota-detail {
    background-color: #fff;
    padding: 10px 20px;

    .br-input {
      width: 100%;
    }

    .table-edit-button {
      margin-left: 20px;
    }

    .title {
      line-height: 40px;
      font-weight: 600;
      font-size: 16px;
    }

    .info-list {
      padding: 10px;

      li {
        float: left;
        width: 32%;
        line-height: 30px;
        font-size: 14px;
        padding: 0 4px;

        .label {

        }

        .info {
          color: #606266;
        }
      }
    }

    .table-box {
      padding: 10px;
    }
  }
</style>
